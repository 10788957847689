.odds-span,
.date-hour-span,
.odds-spanTeam,
.odds-span-box {
    width: 8.5%;
    min-width: 75px;
    float: left;
    padding: 5px 0;
}

.odds-spanTeam {
    width: 15%;
}

.odds-span-box {
    width: 12.5%;
}

.odds-span-box-nascar {
    width: 11.1%;
}

.lines {
    &:hover {
        background: lighten($azul, 19);
        color: $white;
    }
}

.date-hour-span {
    width: 18%;
    min-width: 150px;
}

.bg_lines {
    background: lighten($azul,5);
}

.overflowX_scroll {
    overflow-x: scroll;
}

.odds-list {
    min-width: 1060px;
}

.img_logo {
    max-width: 80px;
    max-height: 80px;
}

@media only screen and (max-width: 600px) {
    .img_logo {
        max-width: 45px;
        max-height: 45px;
    }
}

.team_stats {
    background: $azul_to_dark;
    color: $white;
}
.seven_spans{
    width: 14.2%;
    
}
.eight_spans {
    width: 12.5%;
}

.quince_spans {
    width: 6.6%;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 50px;
    }
}

.diez_spans {
    width: 10%;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 50px;
    }
}

.diez_nueve_spans {
    width: 5.2%;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 50px;
    }
}

.diez_ocho_spans {
    width: 5.5%;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 50px;
    }
}

.diezSiete_spans {
    width: 5.8%;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 50px;
    }
}

.font_small {
    font-size: .8rem;
    overflow: hidden;
}