.btn_gradient,
.btn_gradient_light {
    background: $btn_gradient;
    color: $white;

    &:hover {
        color: $white;
        background: $btn_gradient_reverse;
    }
}

.btn_gradient_light,
.btn_orange {
    background: $gradient_w_g;
    border: none;
    padding: 5px 10px;
    border-radius: 50px;
    color: lighten($black, 20);
    border: 1px dotted $white;
    min-width: 100px;
    box-shadow: -2px -2px 5px lighten($black, 50), 2px 2px 5px lighten($black, 50);
    text-decoration: none;

    &:hover {
        color: $white;
        background: $gradient_w_g_reverse;
        color: gray;
    }
}

.btn_orange {
    background: $base;
    border-radius: 5px;
    box-shadow: 0 0 5px $black;
    color: $white;
    border: 1px inset lighten($base, 20);
}

.btn_left,
.btn_right {
    position: absolute;
    top: 3.8vh;
}

.btn_right {
    left: 96%;
}