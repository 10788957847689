/*solids*/
$black:#000;
$white:#fff;
$azul:#015a9a;

$text:$azul;
$base:#ff2410;
$azul_dark:darken($azul, 20) ;
$base_light:lighten(#ff2410,15) ;
$grey:rgb(75, 75, 75);

$fished: $base_light;
$cancelled:rgb(250, 61, 61);
$in_progress:rgb(18, 169, 18);
$pending:#21aede;
/*GRADIENTES*/
$gradient_w_g: linear-gradient(to bottom, $white 0%,darken($white,20) 80%);
$gradient_w_g_reverse: linear-gradient(to bottom, darken($white,20) 0%,$white 80%);
$azul_to_dark: linear-gradient(to top, $azul 0%,$azul_dark 70%);
$btn_gradient: linear-gradient(to bottom, $base 0%,darken($base,30) 70%);
$btn_gradient_reverse: linear-gradient(to bottom, darken($base,30) 0%, $base 70%);
