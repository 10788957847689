.logo {
    max-height: 100px;
}

.ul-drop,
.ul-drop-2,
.ul-drop-3,
.ul-drop-movil {
    
    &:hover {
        background-color: $azul;
    }

    ul {
        color: rgba($black, .8);
        position: absolute;
        display: none;
        list-style: none;
        background: $white;
        box-shadow: 0 0 4px rgba($black, .5);
        word-break: normal;
        padding: 0;
        
        li {
            a {
                color: rgba($black, .8);
                
                &:hover {
                    color: $white;
                }
            }
        }
    }
    
    &:hover {
        ul {
            display: block;
            z-index: 1231321;
            &:hover {
                .li_drop {
                    color: $white;
                }
            }
        }
    }
}

.ul-drop-2 {
    ul {
        position: relative;
    }

    .drop_span {
        position: absolute;
        left: 35%;
        min-width: 700px;
        z-index: 12355;
    }
}

.li_drop {
    text-align: center;
    padding: 10px;

    &:hover {
        background: $azul;
    }
}

/* menu movil */

.ul-drop-movil {
    ul {
        right: 5%;
        li {
            a {
                color: rgba($black, .8);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.ul-drop-3 {
    ul{
        position: relative;
    }
    .drop_span {
        position: absolute;
        min-width: 1200px;
        right: 5%;
    }
}