#miFormNcaa_logo_baseball{
    .card{
        min-height: 930px;
    }
}
@media screen and (min-width: 600px) {
    #offcanvasRegistro{
        min-width: 35%;
    }
  }
