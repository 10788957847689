@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;900&display=swap');

.font_bold{
    font-family: 'Heebo', sans-serif;
    font-weight: bold;
}
.font_orange{
    color: darken($azul_dark, 20);
}
.font_shadow{
    text-shadow: 0 0 5px $black;
}