body {
  margin: 0;
  background: darken($azul, 20);
  font-family: 'Roboto', sans-serif;
}
.mx-hv-100{
  max-height: 100vh;
}
//backgrounds
.bg_base {
  background-color: $azul;
}

.bg_grey {
  background: $grey;
}

.bg_base_dark {
  background: lighten($azul, 10);
}
.bg_gadient_dark{
  background: $azul_to_dark;
}
.bg_light {
  background: $base;
}

.bg_azul {
  background:darken($azul, 20);
}

.shadow_spans {
  box-shadow: 0 0 5px $black;
}

.loader {
  width: 100%;
  height: 100vh;
  background-image: url('../img/loader.webp');
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: center;
}

.border_top {
  border-top: 3px groove $azul;
}

.border_right {
  border-right: 1px groove lighten($black, 98);
}
.border_left {
  border-left: 1px groove lighten($black, 98);
}
.bb1px {
  border-bottom: 1px solid rgba($grey, .80);
}

.bb2px {
  border-bottom: 2px solid rgba($grey, .80);
}

.chart {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .chart {
    width: 55%;
    margin: auto;
  }
}

.modal-content {
  z-index: 1236;
}

.modal-backdrop {
  display: none;
}

.error {
  background-image: url('../img/404.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
}

.shadow_orange {
  box-shadow: 0 0 15px $azul_dark;
}

.maxH {
  max-height:45vh;
  overflow: scroll;
}

.mh-25 {
  min-height: 25vh;
}

.mh_display {
  min-height: 73vh;
}

/*bootstrap*/
.offcanvas {
  background: darken($azul, 20);
}

.offcanvas {
  height: 100vh;
}


@media only screen and (max-width: 600px) {
  #search_input{
    .offcanvas {
      height: 100vh;
      background: $white;
    }
  }
}
#search_input{
  .offcanvas {
    height: 85vh;
    background: $white;
  }
}
